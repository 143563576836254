/**
 * External dependencies
 */
import Modal from '../Modal';

const PhotoGuidelines = () => {
	return (
		<Modal
			triggerLabel={'Click here to view our photo guidelines'}
			dialogTitle="Photo guidelines"
		>
			<>
				<div className="flex flex-col md:flex-row gap-8">
					<div className="basis-1/2">
						<h3 className="font-bold text-science-blue mb-2">
							Here is a sample of what you can submit
						</h3>

						<div className="text-center">
							<img
								src="/pic-sample.jpg"
								className="mx-auto"
								alt=""
							/>
						</div>
					</div>

					<div className="basis-1/2">
						<h3 className="font-bold text-science-blue mb-2">
							Here is what it will look like in the booklet
						</h3>

						<div className="text-center">
							<img
								src="/pic-sample-booklet.jpg"
								className="mx-auto"
								alt=""
							/>
						</div>
					</div>
				</div>

				<div className="flex flex-col md:flex-row gap-8 mt-4">
					<div className="basis-1/2">
						<ul className="list-disc pl-5">
							<li className="mb-2">
								Color photo of head and shoulders
							</li>
							<li className="mb-2">
								You should be facing the camera straight on
							</li>
							<li className="mb-2">
								Acceptable file formats: JPEG (image quality set
								to maximum if possible, PNG, TIF, or EPS)
							</li>
							<li>
								Maximum file size: 10MB
								<br />
								Minimum file size: 96KB
							</li>
						</ul>
					</div>

					<div className="basis-1/2">
						<p>Some tips</p>
						<ul className="list-disc pl-5">
							<li className="mb-2">
								You can use your phone&apos;s camera against a
								plain background but ask someone else to be your
								photographer
							</li>
							<li>
								This booklet is seen by your peers and
								supervisors. Make sure your photo isn&apos;t too
								candid and represents you as the professional
								you are
							</li>
						</ul>
					</div>
				</div>

				<h4 className="font-bold mt-4">
					Here are some samples of what NOT to submit
				</h4>

				<div className="flex flex-col md:flex-row gap-8 mt-4">
					<div className="basis-1/2">
						<ul className="pl-5 mt-4">
							<li>
								<img src="/pic-sample-1.jpg" alt="" />

								<ul className="list-disc mt-4">
									<li>Selfies</li>
									<li>Object in the way</li>
									<li>Cut off limbs</li>
								</ul>
							</li>
							<li className="mt-8">
								<img src="/pic-sample-2.jpg" alt="" />

								<ul className="list-disc mt-4">
									<li>Group shots</li>
									<li>Side angle</li>
								</ul>
							</li>
							<li className="mt-8">
								<img src="/pic-sample-3.jpg" alt="" />

								<ul className="list-disc mt-4">
									<li>Too light</li>
									<li>Too dark</li>
								</ul>
							</li>
						</ul>
					</div>

					<div className="basis-1/2">
						<ul className="pl-5 mt-8">
							<li>
								<img src="/pic-sample-4.jpg" alt="" />

								<ul className="list-disc mt-4">
									<li>Too pixelated</li>
									<li>Too low-resolution</li>
									<li>Too blurry</li>
								</ul>
							</li>
							<li className="mt-8">
								<img src="/pic-sample-5.jpg" alt="" />

								<ul className="list-disc mt-4">
									<li>Cut off head</li>
									<li>Tight crop</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</>
		</Modal>
	);
};

export default PhotoGuidelines;
