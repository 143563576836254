/**
 * External dependencies
 */
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Internal dependencies
 */
import AppContext from '../AppProvider/AppContext';

const ProtectedRoute = ({ children }) => {
	const { session } = useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		// Use setTimeout to update the message after 2000 milliseconds (2 seconds)
		const timeoutId = setTimeout(() => {
			if (!session) {
				navigate('/login');
			}
		}, 500);

		// Cleanup function to clear the timeout if the component unmounts
		return () => clearTimeout(timeoutId);
	}, [session, navigate]);

	if (!session) {
		return <p>Please wait...</p>;
	}

	return children;
};

export default ProtectedRoute;
