/**
 * External dependencies
 */
import { Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';

/**
 * Internal dependencies
 */
import { SUPABASE_CLIENT as supabaseClient } from './constants';
import Login from './components/Login';
import Edit from './components/Edit';
import ThankYou from './components/ThankYou';
import ProtectedRoute from './components/ProtectedRoute';
import Provider from './components/AppProvider';
import Loading from './components/Loading';
import './App.css';

function App() {
	const [session, setSession] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		supabaseClient.auth
			.getSession()
			.then(({ data: { session: supabaseSession }, error }) => {
				setLoading(false);
				if (error) {
					return;
				}

				setSession(supabaseSession);
			});

		const {
			data: { subscription },
		} = supabaseClient.auth.onAuthStateChange((_event, supabaseSession) => {
			setSession(supabaseSession);
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	if (loading) {
		return <Loading count={1} />;
	}

	return (
		<Provider value={{ session, supabaseClient }}>
			<Routes>
				<Route index element={<Login />} />
				<Route path="login" element={<Login />} />
				<Route
					path="edit"
					element={
						<ProtectedRoute>
							<Edit />
						</ProtectedRoute>
					}
				/>
				<Route
					path="thank-you"
					element={
						<ProtectedRoute>
							<ThankYou />
						</ProtectedRoute>
					}
				/>
			</Routes>
		</Provider>
	);
}

export default App;
