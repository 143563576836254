/**
 * Internal dependencies
 */
import Masthead from '../Masthead';
import Sidebar from '../Sidebar';
import Success from '../Success';
import Footer from '../Footer';

const ThankYou = () => {
	return (
		<div className="layout">
			<Masthead />

			<div className="max-w-7xl mx-auto px-5 pyt-0 pyb-5">
				<div className="flex flex-col gap-8 lg:flex-row">
					<aside className="lg:basis-1/3 order-2 lg:order-1">
						<Sidebar />
					</aside>

					<main className="lg:basis-2/3 order-1 lg:order-2">
						<Success />
					</main>
				</div>

				<Footer />
			</div>
		</div>
	);
};

export default ThankYou;
