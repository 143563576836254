/**
 * External dependencies
 */
import { useNavigate } from 'react-router-dom';
import { Auth } from '@supabase/auth-ui-react';
import { useEffect, useContext } from 'react';

/**
 * Internal dependencies
 */
import AppContext from '../AppProvider/AppContext';
import './index.css';

const Login = () => {
	const { session, supabaseClient } = useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (session) {
			navigate('/edit');
		}
	}, [session, navigate]);

	if (session) {
		return null;
	}

	return (
		<div className="max-w-980 mx-auto p-5">
			<div className="w-full pt-5 pb-10 text-center">
				<img
					src="/logo.jpg"
					alt=""
					width={400}
					height={28}
					className="mx-auto"
				/>
			</div>

			<h3 className="text-center text-2xl font-bold">
				Log in with your Novo Nordisk email and password.
			</h3>

			<div className="supabase__auth">
				<Auth
					supabaseClient={supabaseClient}
					appearance={{
						extend: true,
						className: {
							label: 'supabase__label',
							input: 'supabase__input',
						},
					}}
					providers={[]}
					showLinks={false}
					localization={{
						variables: {
							sign_in: {
								email_label: 'Email',
								password_label: 'Password',
								button_label: 'Login',
								email_input_placeholder: 'Novo Nordisk Email',
							},
						},
					}}
				/>
			</div>
		</div>
	);
};

export default Login;
