/**
 * External dependencies
 */
import { createClient } from '@supabase/supabase-js';

export const SUPABASE_CLIENT = createClient(
	process.env.REACT_APP_SUPABASE_URL,
	process.env.REACT_APP_SUPABASE_ANON_KEY
);

export const QUESTION =
	'As you reflect on Novo Nordisk’s milestone 100 year anniversary, what are you most proud to have accomplished in 2023?';
