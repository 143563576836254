/**
 * External dependencies
 */
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
import Deadline from '../Deadline';

const Success = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return <Deadline>Your information has been updated.</Deadline>;
};

export default Success;
