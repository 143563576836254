/**
 * External dependencies
 */
import Modal from '../Modal';

const SampleQuote = () => {
	return (
		<Modal triggerLabel="See sample" dialogTitle="Sample quote">
			<p>
				The marketplace is always changing, but patients&apos; needs
				remain constant. I relied on focusing on the knowledge that we
				have the best products to help patients. When interacting with
				customers I made sure I knew how to address their concerns in
				order to drive change.
			</p>
		</Modal>
	);
};

export default SampleQuote;
