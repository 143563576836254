/**
 * External dependencies
 */
import { Cross1Icon } from '@radix-ui/react-icons';
import * as Dialog from '@radix-ui/react-dialog';

const Modal = ({ triggerLabel, dialogTitle, children }) => {
	return (
		<Dialog.Root>
			<Dialog.Trigger asChild>
				<span className="text-science-blue underline cursor-pointer hover:text-cerulean focus:text-cerulean">
					{triggerLabel}
				</span>
			</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className="animate-overlayShow inset-0 fixed bg-overlay backdrop-blur-sm" />
				<Dialog.Content
					className={`bg-white fixed animate-contentShow top-1/2 left-1/2 w-[90vw] max-w-[850px] max-h-[85vh] -translate-x-1/2 -translate-y-1/2 overflow-y-scroll text-base`}
				>
					<Dialog.Title className="text-xl bg-science-blue text-white py-2.5 px-5">
						{dialogTitle}
					</Dialog.Title>

					<div className="p-5">{children}</div>

					<Dialog.Close asChild>
						<button
							className="rounded-full h-6 w-6 inline-flex	items-center justify-center	text-white absolute top-2.5 right-2.5 "
							aria-label="Close"
						>
							<Cross1Icon />
						</button>
					</Dialog.Close>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

export default Modal;
