/**
 * External dependencies
 */
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Loading = ({ count = 10, height = 60 }) => {
	return <Skeleton count={count} height={height} />;
};

export default Loading;
