/**
 * External dependencies
 */
import { useEffect, useState, useContext } from 'react';

/**
 * Internal dependencies
 */
import Loading from '../Loading';
import AppContext from '../AppProvider/AppContext';

const UploadedPicture = ({ picturePath }) => {
	const { supabaseClient } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [pictureURL, setPictureURL] = useState('');

	useEffect(() => {
		if (!picturePath) {
			return;
		}

		setLoading(true);
		async function getPicture() {
			const { data, error } = await supabaseClient.storage
				.from('images')
				.createSignedUrl(picturePath, 240);

			if (error) {
				setLoading(false);
			}
			setPictureURL(data?.signedUrl);
		}

		getPicture();
	}, [picturePath, supabaseClient]);

	useEffect(() => {
		if (loading && pictureURL) {
			setLoading(false);
		}
	}, [pictureURL, loading]);

	if (loading) {
		return <Loading count={1} height={200} />;
	}

	return (
		<>
			{pictureURL && (
				<img
					src={pictureURL}
					className="ml-auto"
					alt=""
					decoding="auto"
					// eslint-disable-next-line react/no-unknown-property
					fetchpriority="high"
					loading="eager"
				/>
			)}

			{!pictureURL && (
				<img src="/pic-sample-photo.jpg" alt="" className="ml-auto" />
			)}
		</>
	);
};

export default UploadedPicture;
