/**
 * External dependencies
 */
import { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Internal dependencies
 */
import AppContext from '../AppProvider/AppContext';

const Menu = () => {
	const navigate = useNavigate();
	const { supabaseClient } = useContext(AppContext);

	const handleLogout = useCallback(
		async (event) => {
			event.preventDefault();

			await supabaseClient.auth.signOut({ scope: 'local' });
			navigate('/login');
		},
		[navigate, supabaseClient]
	);

	return (
		<div className="pt-5 pb-5">
			<ul>
				<li>
					<a
						href="mailto:info@nncoewinners.com"
						className="text-science-blue no-underline hover:underline font-bold hover:text-cerulean focus:text-cerulean"
					>
						Contact Us
					</a>
				</li>
				<li>
					<a
						href="/logout"
						onClick={handleLogout}
						className="text-science-blue no-underline hover:underline font-bold hover:text-cerulean focus:text-cerulean"
					>
						Logout
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Menu;
