/**
 * External dependencies
 */
import { useContext } from 'react';
import { Dashboard } from '@uppy/react';
import { v4 as uuidv4 } from 'uuid';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import '@uppy/dashboard/dist/style.min.css';

/**
 * Internal dependencies
 */
import AppContext from '../AppProvider/AppContext';
import useUserFolder from '../../hooks/useUserFolder';

const Upload = ({ onCompleteSuccessful }) => {
	const { session } = useContext(AppContext);
	const folderName = useUserFolder();

	const uppy = new Uppy({
		id: 'uppy',
		autoProceed: true,
		restrictions: {
			maxFileSize: null, // null means no limit
			maxNumberOfFiles: 1,
			allowedFileTypes: ['image/*'],
		},
	}).use(Tus, {
		endpoint: process.env.REACT_APP_SUPABASE_STORAGE_URL,
		headers: {
			authorization: `Bearer ${session.access_token}`,
			apikey: process.env.REACT_APP_SUPABASE_ANON_KEY,
		},
		uploadDataDuringCreation: true,
		removeFingerprintOnSuccess: true, // Important if you want to allow re-uploading the same file https://github.com/tus/tus-js-client/blob/main/docs/api.md#removefingerprintonsuccess
		metadata: {
			bucketName: process.env.REACT_APP_SUPABASE_STORAGE_BUCKET_NAME,
			objectName: 'image.png',
			contentType: 'image/png',
			cacheControl: 3600,
		},
		chunkSize: 6 * 1024 * 1024,
		allowedMetaFields: [
			'bucketName',
			'objectName',
			'contentType',
			'cacheControl',
			'name',
		],
		onError(error) {
			// eslint-disable-next-line no-console
			console.log('Failed because: ' + error);
		},
	});

	uppy.on('file-added', (file) => {
		const uniqueFilename = uuidv4();

		file.meta = {
			...file.meta,
			bucketName: process.env.REACT_APP_SUPABASE_STORAGE_BUCKET_NAME,
			objectName: `${folderName}/${uniqueFilename}`,
			name: uniqueFilename,
			contentType: file.type,
		};
	});

	uppy.on('complete', (result) => {
		onCompleteSuccessful?.(result.successful);
	});

	if (!session) {
		return null;
	}

	return (
		<div className="mt-5">
			<Dashboard
				uppy={uppy}
				proudlyDisplayPoweredByUppy={false}
				width="100%"
				showSelectedFiles={false}
				disableThumbnailGenerator={true}
				height="120px"
			/>
		</div>
	);
};

export default Upload;
