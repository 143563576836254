/**
 * Internal dependencies
 */
import AppContext from './AppContext';

export const { Consumer, Provider } = AppContext;

const AppProvider = Provider;

export default AppProvider;
