const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<footer className="border-t border-blue-haze mt-10 mb-10 p-5">
			<p className="text-center text-americano text-xs">
				&reg; {year} Novo Nordisk All rights reserved
			</p>
		</footer>
	);
};

export default Footer;
