/**
 * External dependencies
 */
import { useEffect, useState, useContext } from 'react';
import slug from 'slug';

/**
 * Internal dependencies
 */
import AppContext from '../components/AppProvider/AppContext';

const useUserFolder = () => {
	const [folderName, setFolderName] = useState('');
	const { session } = useContext(AppContext);

	useEffect(() => {
		async function getUser() {
			setFolderName(
				slug(
					`${session?.user?.user_metadata?.first_name}-${session?.user?.user_metadata?.last_name}`
				)
			);
		}

		getUser();
	}, [session]);

	return folderName;
};

export default useUserFolder;
