/**
 * Internal dependencies
 */
import Menu from '../Menu';

const Sidebar = () => {
	return (
		<div className="pb-5">
			<div className="border-b border-blue-haze mt-5 pb-5">
				<p className="text-science-blue text-base m-0">
					This site has been created to collect information for the
					2023 COE commemorative book. Each year, the book contains
					quotes and photos from each COE winner.
				</p>

				<p className="text-science-blue text-base mt-4">
					While COE is celebrating your success in 2023, your COE
					celebration and the achievements noted will be in your 2024
					alignment.
				</p>

				<p className="text-science-blue text-base mt-4">
					Please review the information on the right to confirm it is
					correct, and add a statement about your success in 2023.
				</p>
			</div>

			<Menu />

			<div className="sidebar__picture">
				<img src="/sidebar-image.png" alt="" loading="lazy" />
			</div>
		</div>
	);
};

export default Sidebar;
